import * as v from 'valibot';
import { FileImageSizes, FileType } from '~/.server/drizzle/entity/FileEntity';
import form from '~/components/valibot/form';

export type DeliverableFileImage = FileImageSizes;
export type DeliverableFileVideo = { m3u8: string; mp4: string };

export interface DeliverableFile {
  id: string;
  type: FileType;
  name: string;
  pdf?: string;
  image?: DeliverableFileImage;
  video?: DeliverableFileVideo;
}

export enum DeliverableType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  SOCIAL = 'SOCIAL',
  SLIDESHOW = 'SLIDESHOW',
  BROCHURE = 'BROCHURE',
  AERIAL = 'AERIAL',
  MATTERPORT = 'MATTERPORT',
  FLOORPLAN = 'FLOORPLAN',
}

export enum DeliverableVisibility {
  BOTH = 'BOTH',
  BRANDED = 'BRANDED',
  UNBRANDED = 'UNBRANDED',
  NONE = 'NONE',
}

export const DeliverableTypeName: Record<DeliverableType, string> = {
  [DeliverableType.AERIAL]: 'Aerial',
  [DeliverableType.MATTERPORT]: 'Matterport',
  [DeliverableType.FLOORPLAN]: 'Floorplans',
  [DeliverableType.SOCIAL]: 'Social',
  [DeliverableType.BROCHURE]: 'Brochures',
  [DeliverableType.PHOTO]: 'Photos',
  [DeliverableType.VIDEO]: 'Videos',
  [DeliverableType.SLIDESHOW]: 'Slideshow',
};

export const DeliverableTypes = Object.values(DeliverableType);

export interface Deliverable<T extends DeliverableType | any = any> {
  id: string;
  data: T extends DeliverableType ? Extract<DeliverableData, { type: T }> : DeliverableData;
  file?: DeliverableFile;
  created?: TDateISO;
}

export const DeliverableDataPhotoSchema = v.object({
  type: v.literal(DeliverableType.PHOTO),
  caption: v.optional(v.string()),
});

export type DeliverableDataPhoto = v.InferOutput<typeof DeliverableDataPhotoSchema>;

export const DeliverableDataSocialSchema = v.object({
  type: v.literal(DeliverableType.SOCIAL),
  caption: v.optional(v.string()),
  raw: v.optional(form.boolean),
  visibility: v.optional(v.enum(DeliverableVisibility)),
});

export type DeliverableDataSocial = v.InferOutput<typeof DeliverableDataSocialSchema>;

export const DeliverableDataVideoSchema = v.object({
  type: v.literal(DeliverableType.VIDEO),
  caption: v.optional(v.string()),
  branded: v.optional(form.boolean),
  raw: v.optional(form.boolean),
});

export type DeliverableDataVideo = v.InferOutput<typeof DeliverableDataVideoSchema>;

export const DeliverableDataAerialSchema = v.object({
  type: v.literal(DeliverableType.AERIAL),
  caption: v.optional(v.string()),
  boundary: v.optional(v.pipe(v.unknown(), v.transform(Boolean)), false),
  markers: v.optional(v.pipe(v.unknown(), v.transform(Boolean)), false),
});

export type DeliverableDataAerial = v.InferOutput<typeof DeliverableDataAerialSchema>;

export const DeliverableDataBrochureSchema = v.object({
  type: v.literal(DeliverableType.BROCHURE),
  profile_id: v.optional(v.string()),
});

export type DeliverableDataBrochure = v.InferOutput<typeof DeliverableDataBrochureSchema>;

export const DeliverableDataSlideshowSchema = v.object({
  type: v.literal(DeliverableType.SLIDESHOW),
  profile_id: v.optional(v.string()),
  generating: v.boolean(),
});

export type DeliverableDataSlideshow = v.InferOutput<typeof DeliverableDataSlideshowSchema>;

export const DeliverableDataFloorplanSchema = v.object({
  type: v.literal(DeliverableType.FLOORPLAN),
  floors: v.fallback(v.array(form.number()), []),
});

export type DeliverableDataFloorplan = v.InferOutput<typeof DeliverableDataFloorplanSchema>;

export const DeliverableDataMatterportSchema = v.object({
  type: v.literal(DeliverableType.MATTERPORT),
  url: v.pipe(
    v.string('A branded URL is required.'),
    v.url(),
    v.check(
      (url: string) => url.startsWith('https://my.matterport.com/show/?m='),
      'The URL must start with "https://my.matterport.com/show/?m=".',
    ),
    v.transform((url: string) => {
      const parsed = new URL(url);

      const m = parsed.searchParams.get('m');

      // Remove Matterport specific variables
      // https://support.matterport.com/s/article/URL-Parameters?language=en_US
      parsed.search = '';

      if (m) {
        parsed.searchParams.set('m', m);
      }

      return parsed.toString();
    }),
  ),
});

export type DeliverableDataMatterport = v.InferOutput<typeof DeliverableDataMatterportSchema>;

export const DeliverableDataSchema = v.variant('type', [
  DeliverableDataPhotoSchema,
  DeliverableDataSocialSchema,
  DeliverableDataVideoSchema,
  DeliverableDataFloorplanSchema,
  DeliverableDataMatterportSchema,
  DeliverableDataSlideshowSchema,
  DeliverableDataAerialSchema,
  DeliverableDataBrochureSchema,
]);

export type DeliverableData = v.InferOutput<typeof DeliverableDataSchema>;
